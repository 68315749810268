import React, { FC, useState, useEffect, Fragment } from "react";
import styled from "styled-components";
import Link from "next/link";
import { fuego, useCollection } from "@nandorojo/swr-firestore";
import {
	Badge,
	Box,
	Card,
	CardActionArea,
	CardMedia,
	CardContent,
	Grid,
	Paper,
} from "@mui/material";
import { MovieCreationOutlined } from "@mui/icons-material";

import { useAuth } from "components/common/HOC/AuthProvider";
import { black15 } from "config/colors";
import {
	AddNewProjectButton,
	Card as CommonCard,
	Navigation,
} from "components/common";
import { ProjectOverviewProps } from "components/pages/projects/types";
import {
	CardListContainer,
	CardBottomSpacer,
} from "components/common/CardList/CardList";

const PaperHeaderContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 20px 20px 10px;
`;

const PaperHeader = styled.h3`
	font-size: 18px;
	margin: 0 10px 0 0;
`;

const ProjectHeaderTitleContainer = styled.div`
	display: flex;
	align-items: center;
`;

const ProjectOuterContainer = styled.div`
	padding: 0 12px 20px;
`;

const ProjectContainer = styled.div`
	width: 100%;
	padding: 20px 12px;
	display: grid;
	grid-auto-flow: column;
	grid-auto-columns: min-content;
	column-gap: 40px;
	overflow: auto;

	&::-webkit-scrollbar {
		height: 8px;
	}
	&::-webkit-scrollbar-thumb {
		border-radius: 4px;
		background: ${black15};
	}

	scrollbar-color: ${black15} transparent;
	scrollbar-width: thin;
	scrollbar-face-color: ${black15};
	scrollbar-track-color: transparent;
`;

const ProjectIconFillerContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 170px;
	width: 100%;
`;

const ProjectTitle = styled.h4`
	font-size: 16px;
	line-height: 18px;
	height: 36px;
	display: flex;
	align-items: center;
	text-overflow: ellipsis;
	overflow: hidden;
	margin: 10px 0;
`;

const CardLink = styled.a`
	width: 100%;
	text-decoration: none;
`;

function Index() {
	const { user }: any = useAuth();
	const [projectLogoDownloadURLs, setProjectLogoDownloadURLs] = useState({});

	// Temp remove for user testing: where: [[`participants.${user.id}`, "in", ["read", "write"]]],
	const { data: projectData } = useCollection<ProjectOverviewProps>(
		`projects`,
		{}
	);

	useEffect(() => {
		if (projectData) {
			const storage = fuego.storage();
			projectData.forEach((project) => {
				const projectImageRef = storage
					.ref()
					.child(`projects/${project.id}/clientLogo`);
				var projectDownloadURL = "";
				projectImageRef
					.getDownloadURL()
					.then((url) => {
						var xhr = new XMLHttpRequest();
						xhr.responseType = "blob";
						xhr.onload = (event) => {
							var blob = xhr.response;
						};
						xhr.open("GET", url);
						xhr.send();

						if (url)
							setProjectLogoDownloadURLs(
								(projectLogoDownloadURLs) => ({
									...projectLogoDownloadURLs,
									[project.id]: url,
								})
							);
					})
					.catch((error) => {});
			});
		}
	}, [projectData]);

	const renderProjectsView = () =>
		projectData &&
		projectData.map((project, i) => (
			<Badge color="secondary" variant="dot" invisible={true} key={i}>
				<Link
					href={
						project.id !== undefined
							? `/projects/${project.id}?day=1`
							: "projects"
					}
					passHref
				>
					<CardLink>
						<Card
							style={{
								width: 170,
								borderBottom: `5px solid ${
									project?.projectColor || black15
								}`,
							}}
						>
							<CardActionArea>
								{projectLogoDownloadURLs[project.id] ? (
									<CardMedia
										component="img"
										height="170"
										alt="Project logo"
										image={
											projectLogoDownloadURLs[project.id]
										}
									/>
								) : (
									<ProjectIconFillerContainer>
										<MovieCreationOutlined fontSize="large" />
									</ProjectIconFillerContainer>
								)}
								<CardContent>
									<ProjectTitle>
										{project?.projectName ||
											"Untitled Project"}
									</ProjectTitle>
								</CardContent>
							</CardActionArea>
						</Card>
					</CardLink>
				</Link>
			</Badge>
		));

	const renderActions = () => {
		return (
			<CardListContainer maxHeight="245px">
				{projectData &&
					projectData.map((project) => {
						const filteredActions = project?.actions.length
							? project?.actions.filter(
									(action) => !action.isChecked
							  )
							: [];

						return (
							<Fragment key={project?.id}>
								{filteredActions.map(
									({
										detail,
										id,
										isChecked,
										action,
										link,
										data,
									}) => (
										<CommonCard
											viewOnly={true}
											detail={detail}
											id={id}
											isChecked={isChecked}
											action={action}
											data={data}
											link={link}
											key={id}
											prefix={
												project?.projectName ||
												"Untitled Project"
											}
										/>
									)
								)}
							</Fragment>
						);
					})}
				<CardBottomSpacer />
			</CardListContainer>
		);
	};

	return (
		<Navigation breadCrumbItems={["Home"]} selectedKeys={["1"]}>
			<Box px={4} mb={3}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Paper style={{ overflow: "hidden", paddingRight: 12 }}>
							<PaperHeaderContainer>
								<ProjectHeaderTitleContainer>
									<PaperHeader>Projects</PaperHeader>
									<AddNewProjectButton />
								</ProjectHeaderTitleContainer>
								<Link href="/projects">
									<a>See all</a>
								</Link>
							</PaperHeaderContainer>
							<ProjectOuterContainer>
								<ProjectContainer>
									{renderProjectsView()}
								</ProjectContainer>
							</ProjectOuterContainer>
						</Paper>
					</Grid>
					<Grid item xs={12}>
						<Paper
							style={{
								overflow: "hidden",
								paddingRight: 6,
								height: 300,
							}}
						>
							<PaperHeaderContainer>
								<PaperHeader>Actions</PaperHeader>
								<div></div>
							</PaperHeaderContainer>
							{renderActions()}
						</Paper>
					</Grid>
				</Grid>
			</Box>
		</Navigation>
	);
}

export default Index;
